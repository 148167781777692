import React, { memo } from 'react';
import { CommentCount } from '../../types/Comment';

interface CommentCountItemProps {
  commentCount: CommentCount;
}

const CommentCountItem: React.FC<CommentCountItemProps> = ({ commentCount }) => {
  return (
    <>
      {commentCount.comments > 0 && (
        <span>
          {commentCount.comments} {commentCount.comments > 1 ? 'comments' : 'comment'}
        </span>
      )}
      {commentCount.resolved > 0 && ', '}
      {commentCount.resolved > 0 && <span>{commentCount.resolved} resolved</span>}
    </>
  );
};

export default memo(CommentCountItem);
