import React, { memo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, LinearProgress, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DocumentDiffChangeMap, DocumentNode } from '../../types/RealtaDocument';
import { documentService } from '../../services/DocumentService';
import CommentCountItem from './CommentCountItem';
import { CommentCount } from '../../types/Comment';
import { getDiffColor } from '../CompareDocument/utils';

const TOCItem: React.FC<{
  documentId: string;
  node: DocumentNode;
  loadComment: boolean;
  commentCount?: CommentCount;
  diffChanges?: DocumentDiffChangeMap;
  onSelect: (node: DocumentNode) => void;
}> = ({ node, documentId, onSelect, loadComment, commentCount, diffChanges }) => {
  const [expandedSectionAccordion, setExpandedSectionAccordion] = useState<string | null>(null);
  const [subSections, setSubSections] = useState<DocumentNode[]>([]);
  const [commentCounts, setCommentCounts] = useState<CommentCount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchSections = async () => {
    setIsLoading(true);

    const sections = await documentService.getSubSectionsOfSection({ sectionUuid: node.uuid });
    setSubSections(sections);

    if (loadComment) {
      const commentCounts = await documentService.getNodeSubSectionComments({ uuid: node.uuid, isDocument: false });
      setCommentCounts(commentCounts);
    }

    setIsLoading(false);
  };

  const onClickSection = async (sectionUuid: string) => {
    if (expandedSectionAccordion === sectionUuid) {
      setExpandedSectionAccordion(null);
    } else {
      setExpandedSectionAccordion(sectionUuid);
      await fetchSections();
    }
  };

  const handleChangePage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onSelect(node);
  };

  const getDiffChangeType = (sectionUuid: string) => {
    return diffChanges?.get(sectionUuid)?.type;
  };

  return (
    <Accordion
      key={node.uuid}
      expanded={expandedSectionAccordion === node.uuid}
      onChange={() => onClickSection(node.uuid)}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              pointerEvents: 'auto',
            }}
          />
        }
      >
        <Box sx={{ color: '#000' }} onClick={handleChangePage}>
          <Box>
            <Box display="flex" justifyContent="space-between" gap={1}>
              <Typography fontWeight={500}>{node.fullId || 'Unnamed'}</Typography>
              {getDiffChangeType(node.uuid) && (
                <Typography fontWeight={500} color={getDiffColor(getDiffChangeType(node.uuid), true)}>
                  {getDiffChangeType(node.uuid)}
                </Typography>
              )}
            </Box>
            {loadComment && commentCount && (
              <Box>
                <CommentCountItem commentCount={commentCount} />
              </Box>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      {isLoading && <LinearProgress color="secondary" />}
      {subSections.length ? (
        <AccordionDetails>
          {subSections.map((d) => (
            <TOCItem
              key={d.uuid}
              node={d}
              documentId={documentId}
              onSelect={onSelect}
              loadComment={loadComment}
              commentCount={commentCounts.find((c) => c.id === d.uuid)}
              diffChanges={diffChanges}
            />
          ))}
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
};
export default memo(TOCItem);
