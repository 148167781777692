import { DocumentDiffChangeType } from '../../types/RealtaDocument';
import { HEIGHT, WIDTH } from './type';

export const getDiffColor = (type?: DocumentDiffChangeType, isText = false) => {
  switch (type) {
    case DocumentDiffChangeType.DELETED:
      return isText ? 'red' : 'rgba(255, 0, 0, 0.3)';
    case DocumentDiffChangeType.ADDED:
      return isText ? 'green' : 'rgba(0, 255, 0, 0.3)';
    case DocumentDiffChangeType.UPDATED:
      return isText ? '#8B8000' : 'rgba(255, 255, 0, 0.3)';
    default:
      return 'black';
  }
};

export function inchToPx(inches: number): number {
  // Physical dimensions of A4 paper in inches
  const physicalWidthInInches = 8.27;
  const physicalHeightInInches = 11.69;

  // Calculate PPI (Pixels Per Inch)
  const ppiWidth = WIDTH / physicalWidthInInches;
  const ppiHeight = HEIGHT / physicalHeightInInches;

  // Average PPI for more uniform scaling
  const averagePPI = (ppiWidth + ppiHeight) / 2;

  // Convert inches to pixels
  return Math.round(inches * averagePPI);
}
